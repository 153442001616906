
























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import DialogActions from '@/components/ui/DialogActions.vue';

@Component({
  components: {
    DialogActions,
  },
})
export default class Dialog extends Vue {
  @Prop() private title!: string;
  @Prop({default: false}) private saveDisabled!: boolean;
  @Prop({default: false}) private saveLoading!: boolean;
  @Prop({default: false}) private cancelDisabled!: boolean;
  @Prop({default: false}) private cancelLoading!: boolean;
  @Prop({default: false}) private loading!: boolean;
}
