















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {mdiPlus} from '@mdi/js';

@Component
export default class AddButton extends Vue {
  @Prop({default: 'primary'}) private color!: string;
  @Prop({default: false}) private loading!: boolean;
  private iconAdd = mdiPlus;
}
