



















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class DialogActions extends Vue {
  @Prop({default: false}) private saveDisabled!: boolean;
  @Prop({default: false}) private saveLoading!: boolean;
  @Prop({default: false}) private cancelDisabled!: boolean;
  @Prop({default: false}) private cancelLoading!: boolean;
}
