























































































import ProjectDialog from '../components/ProjectDialog.vue';
import Component from 'vue-class-component';
import {ProjectAttributes, SelectItem, Snackbar} from '@/interfaces';
import {authModule, clientModule, projectModule} from '@/store/modules';
import {Role} from '@/enums';
import {
  mdiPencil,
  mdiPlus,
  mdiMagnify,
  mdiArchive,
  mdiDotsVertical,
  mdiTrashCan,
} from '@mdi/js';
import Vue from 'vue';
import PageTitle from '@/components/PageTitle.vue';
import AddButton from '@/components/ui/AddButton.vue';

@Component({
  components: {
    AddButton,
    PageTitle,
    ProjectDialog,
  },
})
export default class Projects extends Vue {
  private clientId: number | null = -1;
  private headersClient = [
    {text: 'Project', value: 'name'},
    {text: 'Description', value: 'description'},
  ];
  private headersStaff = [
    {
      text: 'Client',
      align: 'left',
      name: 'client',
      value: 'client.name',
      cellClass: 'text-no-wrap',
    },
    {text: 'Project', value: 'name'},
    {text: 'Description', value: 'description'},
    {
      align: 'center',
      text: 'Actions',
      value: 'action',
      cellClass: 'text-no-wrap',
    },
  ];
  iconArchive = mdiArchive;
  iconDelete = mdiTrashCan;
  iconEdit = mdiPencil;
  iconMore = mdiDotsVertical;
  iconSearch = mdiMagnify;
  projectDialogShown = false;
  private projectId: string | null = null;
  private roles = Role;
  search = '';
  selected = [];
  snackbar: Snackbar = {visible: false, message: ''};
  statusId = 0;
  statuses: SelectItem[] = [
    {text: 'Active', value: 0},
    {text: 'Archived', value: 1},
  ];

  get role(): Role | null {
    return authModule.role;
  }

  get authClientId(): number | null {
    return authModule.clientId;
  }

  get projects(): ProjectAttributes[] {
    return projectModule.list.map(project => project.attributes);
  }

  get clientsCombobox(): SelectItem[] {
    console.log('goin');
    return [
      ...[{text: 'All', value: -1}],
      ...clientModule.list.map(item => {
        return {
          text: item.attributes.name,
          value: item.id,
        };
      }),
    ];
  }

  get loading(): boolean {
    return projectModule.loading;
  }

  created(): void {
    this.clientId = this.role === Role.Staff ? -1 : this.authClientId;
    this.update();
    if (this.role === Role.Staff) {
      clientModule.loadMulti();
    }
  }

  async archiveProject(id: string): Promise<void> {
    const project = projectModule.find(id);
    if (
      confirm(
        `Are you sure you want to archive the project: ${project.attributes.name}`
      )
    ) {
      project.attributes.archived = true;
      await projectModule.save(id);
      this.snackbar.visible = true;
      this.snackbar.message = 'Project archived';
    }
  }

  closeProjectDialog(message: string) {
    this.projectDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  async deleteProject(id: string): Promise<void> {
    const project = projectModule.find(id);
    if (
      confirm(
        `Are you sure you want to delete the project: ${project.attributes.name}`
      )
    ) {
      await projectModule.delete(id);
      this.snackbar.visible = true;
      this.snackbar.message = 'Project deleted';
    }
  }

  async showProjectDialog(id: string): Promise<void> {
    this.projectId = id;
    if (id === 'new') {
      await projectModule.loadById('new');
      const project = await projectModule.save('new');
      this.projectId = project.id;
    }
    this.projectDialogShown = true;
  }

  async update(): Promise<void> {
    await projectModule.loadMulti({
      filters: {
        clientId: String(this.clientId),
        archived: String(this.statusId),
      },
    });
  }
}
