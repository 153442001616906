











































import Vue from 'vue';
import {clientModule, projectModule} from '@/store/modules';
import {Prop} from 'vue-property-decorator';
import Component from 'vue-class-component';
import {InputValidationRules} from 'vuetify';
import {
  AlertMessage,
  ClientResource,
  ProjectResource,
  SelectItem,
} from '@/interfaces';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import Dialog from '@/components/ui/Dialog.vue';
@Component({
  components: {Dialog, DialogAlert},
})
export default class ProjectDialog extends Vue {
  @Prop({default: 'new'}) private projectId!: string;
  @Prop() private newItem!: string;
  private alert: AlertMessage | null = null;
  private rulesName: InputValidationRules = [
    v => !!v || 'Name is required',
    v => (v && v.length > 3) || 'Name is too short',
  ];
  private rulesRequired: InputValidationRules = [
    v => !!v || 'This field is required',
  ];
  private valid = false;

  get clients(): ClientResource[] {
    return clientModule.list;
  }

  get clientsCombobox(): SelectItem[] {
    return [
      ...[{text: 'All', value: -1}],
      ...clientModule.list.map(item => {
        return {
          text: item.attributes.name,
          value: item.attributes.id,
        };
      }),
    ];
  }

  get loading(): boolean {
    return projectModule.loading;
  }

  get loadingClients(): boolean {
    return clientModule.loading;
  }

  get project(): ProjectResource {
    return projectModule.find(this.projectId);
  }

  get saving(): boolean {
    return projectModule.saving;
  }

  get deleting(): boolean {
    return projectModule.deleting;
  }

  get busy(): boolean {
    return projectModule.busy;
  }

  async created(): Promise<void> {
    console.log(this.projectId);
    await projectModule.loadById(this.projectId);
  }

  async save(): Promise<void> {
    try {
      await projectModule.save(this.project.id);
      this.$emit('close', 'Your project has been saved.');
    } catch (e) {
      this.alert = {message: 'Could not save project', type: 'error'};
    }
  }

  async cancel() {
    if (this.newItem) {
      await projectModule.delete(this.projectId);
    } else {
      // reload the project in case they were changed
      await projectModule.loadById(this.projectId);
    }
    this.$emit('close');
  }
}
